.react-datepicker {
  font-family: 'Arial', sans-serif;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  font-size: 1.2em;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

.react-datepicker__current-month {
  font-size: 1.2em; 
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2em;
  line-height: 2em; 
  margin: 0.166em;
  font-size: 1em;
  color: black;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: #1a202c;
  color: white;
  border-radius: 50%;
}

.date-picker-popper {
  z-index: 4 !important;
}

.react-datepicker__day--disabled {
  color: #ccc;
}
